<script lang="ts" setup>
import type { MoleculesLinkWrapperMediaProps } from './MoleculesLinkWrapperMedia.props'
import { withLeadingSlash } from 'ufo'

const props = defineProps<MoleculesLinkWrapperMediaProps>()
const { openDialog } = useDialog()

const localePath = useLocalePath()

const url = computed(() => {
  // Check if there is an internal link
  if (props.internalLink?.urlSlug) {
    return withLeadingSlash(props.internalLink.urlSlug)
  }
  // Check if props.target is an external URL
  if (props.target && !/^https?:\/\//.test(props.target)) {
    // If it's not an external URL, apply withLeadingSlash
    return localePath(withLeadingSlash(props.target || ''))
  }
  // If it's an external URL, return it directly
  return props.target || ''
})
</script>

<template>
  <button
    v-if="type === 'overlay' || type === 'sidesheet'"
    :aria-label="ariaLabel"
    @click="openDialog(target)"
  >
    <slot />
  </button>
  <NuxtLink
    v-else
    :to="
      type === 'anchor' && !internalLink?.urlSlug
        ? {
            hash: `#${target}`,
          }
        : url
    "
    :target="type === 'blank' ? '_blank' : undefined"
    :aria-label="ariaLabel"
    ><slot
  /></NuxtLink>
</template>
